import React from "react"

import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/BackToSchoolWithPartners"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { FormPKW } from "../components/FormPKW"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { Partners } from "../components/PartnersNew"
import { InfoCards } from "../components/InfoCardsHalva"
import { NewFooter } from "../components/NewFooter"
import { HALVA_PROMO_CAR } from "../components/InfoCardsHalva/helpers"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"
import { autoPromo } from "../components/PartnersNew/helpers"

// Время для таймер
const countDownTime = 1800000

const titlePersonalForm = (
  <>
    Оформите карту сейчас
    <br />и участвуйте в розыгрыше
  </>
)
const partnersTitle = (
  <>
    Более 260 000
    <br className="d-md-none" /> магазинов-партнеров
  </>
)

const partnersDescr = (
  <>
    Совершайте покупки и участвуйте в розыгрыше крупных призов – путешествий,
    <br className="d-none d-md-block" /> fashion и beauty боксов, техники, ювелирных изделий и
    АВТОМОБИЛЯ!
  </>
)

export default function BackToSchool({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint={false} hasCTA countDownTime={countDownTime} />
      <Banner orderNum="1" />
      <FourAdvantagesPoints
        withTitle
        indent={false}
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <HowInstallmentWorks
        headTitle={
          <>
            Как принять участие
            <br className="d-none d-md-block" /> в розыгрыше автомобиля
          </>
        }
        variant="autoPromo"
        orderNum="3"
      />
      <FormPKW
        onlyPKWPage
        dataLayerName="shortPersonalForm"
        withTimer
        countDownTime={countDownTime}
        orderNum="4"
        formBtnText="Оформить карту"
        title={titlePersonalForm}
        longTitle
        isGreenApprove
        progressBar
      />
      <Partners
        variant="autoPromo"
        additionalPartners={autoPromo}
        title={partnersTitle}
        subTitle={partnersDescr}
        darkColor
        orderNum="5"
      />
      <InfoCards title="" variant="autoPromo" cards={HALVA_PROMO_CAR} orderNum="6" />
      <NewFooter ligal={ligal} orderNum="7" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/backtoschool/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
